// COLOR SWATCHES
$lsw-blue: #23a8eb;
$lsw-navy: #272557;

$light-blue: #ddf5ff;

$light-aqua: #eef9ff;
$mid-blue: #c2eeff;

// DEFAULTS
$legacy-support-for-ie: true;
$sprite: "../images/lsw_sprite.png";

::-webkit-input-placeholder {
    color: $lsw-blue;
}
::-moz-placeholder {
    color: $lsw-blue;
}
:-moz-placeholder {
    color: $lsw-blue;
}
:-ms-input-placeholder {
    color: $lsw-blue;
}

// ** Compass opacity mixin
@mixin opacity($opacity) {
    @if $legacy-support-for-ie {
        filter: unquote("progid:DXImageTransform.Microsoft.Alpha(Opacity=#{round($opacity * 100)})");
    }
    opacity: $opacity;
}

@mixin hide-text {
    font: 0/0 a;
    text-shadow: none;
    color: transparent;
    overflow: hidden;
    // IE 6/7 fallback
    *text-indent: 100%;
    white-space: nowrap;
}

@mixin noborder {
    border: none !important;
    border: 0 !important;
    border-color: transparent !important;
}

@mixin text-stroke($color: #fff) {
	text-shadow: -1px -1px 0 $color,  
			    1px -1px 0 $color,
			    -1px 1px 0 $color,
			     1px 1px 0 $color;
}

@mixin vertical-center {
	text-align: center;
	white-space: nowrap;
	&:before {
		content: '';
		display: inline-block;
		height: 100%;
		vertical-align: middle;
	}
	.centered {
		display: inline-block;
		vertical-align: middle;
	}
}

@mixin myClearfix {
    *zoom: 1;

    &:before, &:after {
        content: "";
        display: table;
    }

    &:after {
        height: 0;
        clear: both;
        overflow: hidden;
        visibility: hidden;
    }
}

@mixin imgRep($src: "", $w: "", $h: "", $repeat: "", $x: 0, $y: 0, $bkgColor: "") {
    @if $src != "" {
        display: block;
        position: relative;

        @if $w != "" {
            width: $w;
        }

        @if $h != "" {
            height: $h;
        }

        background-image: unquote("url(#{$src})");

        @if $repeat != "" {
            background-repeat: unquote(#{$repeat});
        }

        background-position: $x $y;
        @if $bkgColor != "" {
            background-color: unquote(#{$bkgColor});
        }
        background-size: auto;
        border: 0;
        padding: 0;
        line-height: 1;
        text-shadow: none;
        text-indent: 100%;
        white-space: nowrap;
        overflow: hidden;
    }
    @else {
        background: none;
    }
}

// Specify hi-res background for retina displays
@mixin image-2x($image, $width, $height) {
  @media (min--moz-device-pixel-ratio: 1.3),
         (-o-min-device-pixel-ratio: 2.6/2),
         (-webkit-min-device-pixel-ratio: 1.3),
         (min-device-pixel-ratio: 1.3),
         (min-resolution: 1.3dppx) {
    // on retina, use image that's scaled by 2
    background-image: url($image);
    background-size: $width $height;
  }
}

// Remove this mixin if using with Compass imports
@mixin experimental($property, $value, $moz: false, $webkit: false, $o: false, $ms: false, $khtml: false, $official: true) {
    
    @if $moz {
        -moz-#{$property}: $value;
    }
    
    @if $webkit {
        -webkit-#{$property}: $value;
    }

    @if $o {
        -o-#{$property}: $value;
    }

    @if $ms {
        -ms-#{$property}: $value;
    }

    @if $khtml {
        -khtml-#{$property}: $value;
    }

    @if $official {
        #{$property}: $value;
    }
}

@mixin animation ( 
    $animation-name: none, // keyframename|none
	$animation-duration: 0s, // time(default=0) 
	$animation-timing-function: ease, // linear|ease(default)|ease-in|ease-out|ease-in-out|cubic-bezier(n,n,n,n)default
	$animation-delay: 0s, // time(default=0)
	$animation-iteration-count: 1, // n|infinite 
	$animation-direction: normal, // normal|alternate
	$animation-fill-mode: none // none(default)|forwards|backwards|both
) {
    @if $animation-name == none {
        @include experimental(animation, none, -moz, -webkit, -o, not -ms, not -khtml, official);
    }
    @else {
        $full: $animation-name $animation-duration $animation-timing-function $animation-delay $animation-iteration-count $animation-direction $animation-fill-mode; 
        @include experimental(animation, $full, -moz, -webkit, -o, not -ms, not -khtml, official);
    }
}

@mixin keyframes($name) {
    @-webkit-keyframes #{$name} {
        @content;
    }

    @-moz-keyframes #{$name} {
        @content;
    }

    @-o-keyframes #{$name} {
        @content;
    }

    @keyframes #{$name} {
        @content;
    }
}

// Calculate fluid ratio based on two dimensions (width/height)
@mixin fluid-ratio($large-size, $small-size) {
    $width-large: nth($large-size, 1);
    $width-small: nth($small-size, 1);
    $height-large: nth($large-size, 2);
    $height-small: nth($small-size, 2);
    $slope: ($height-large - $height-small) / ($width-large - $width-small);
    $height: $height-small - $width-small * $slope;

    padding-top: $slope * 100%;
    height: $height;

    @include background-size(cover); // -webkit-, -moz-, -o-
    background-position: center; // Internet Explorer 7/8
}
@import "normalize";
@import "lswDefaults";
@import "settings";
// @import "foundation";

// Or selectively include components
// @import
//   "foundation/components/accordion",
//   "foundation/components/alert-boxes",
//   "foundation/components/block-grid",
//   "foundation/components/breadcrumbs",
//   "foundation/components/button-groups",
//   "foundation/components/buttons",
//   "foundation/components/clearing",
//   "foundation/components/dropdown",
//   "foundation/components/dropdown-buttons",
//   "foundation/components/flex-video",
//   "foundation/components/forms",
@import  "foundation/components/grid";
//   "foundation/components/inline-lists",
//   "foundation/components/joyride",
//   "foundation/components/keystrokes",
//   "foundation/components/labels",
//   "foundation/components/magellan",
//   "foundation/components/orbit",
//   "foundation/components/pagination",
//   "foundation/components/panels",
//   "foundation/components/pricing-tables",
//   "foundation/components/progress-bars",
//   "foundation/components/reveal",
//   "foundation/components/side-nav",
//   "foundation/components/split-buttons",
//   "foundation/components/sub-nav",
//   "foundation/components/switches",
//   "foundation/components/tables",
//   "foundation/components/tabs",
//   "foundation/components/thumbs",
//   "foundation/components/tooltips",
//   "foundation/components/top-bar",
@import "foundation/components/type";
//   "foundation/components/offcanvas",
//   "foundation/components/visibility";


html {
    -webkit-font-smoothing: antialiased;
    font-smoothing: antialiased;
    text-rendering: optimizeLegibility;
    /*text-shadow: 1px 1px 1px rgba(0,0,0,0.004);*/
}
div, header, footer, section, article, aside, nav {
    position: relative;
}
#container {
    overflow: hidden;
	@include grid-row();
	.header, .collapse, .main-content {
		@include grid-column(12, $collapse: true);
	}
}
.header {
	background: $light-blue;
	height: 60px;
}
.footer {
    @include grid-row(nest-collapse);
    clear: both;
    padding-top: 50px;
    margin-top: 20px;
    background: url('../images/footer_wave.gif') repeat-x 50% 0 $light-aqua;    
    > div {
        @include grid-column(12);
        padding-top: 25px;
        padding-bottom: 15px;
    }
}
#logo {
	position: relative;
	top: 5px;
	left: 10px;
	display: block;
	width: 32%;
	max-width: 206px;
	height: 146px;
	background: url(../images/lsw_logo.png) no-repeat 0 0;
	background-size: 100% auto;
	@include hide-text();
}
.main-content {
	padding-top: 30px;
    h1 {
        margin-bottom: 30px;
    }
}
.countries {
	@include grid-row();
}
.country {
	position: relative;
	width: 100%;
	max-width: 235px;
	min-height: 240px;
	background: $light-blue;
	border-radius: 10px;
	text-align: center;
	overflow: hidden;
	padding-top: 5px;
	margin-bottom: 30px;
	@include grid-column($columns:10, $center: true);
	.crop {
		width: 100%;
		max-width: 235px;
		height: 136px;
		background: url(../images/flag-crop.png) no-repeat 0 0;
		background-size: 100%;
		position: absolute;
		left: 0;
		bottom: 0;
		font-weight: bold;
		font-size: 24px;
		color: $lsw-navy;
		@include text-stroke();
		@include vertical-center();
		.centered {
			text-align: left;
			width: 200px;
			white-space: normal;
            &:last-child {
                top: 10px;
            }
		}
	}
}
.multi-lang {
    @include grid-row();
    > a {
        position: relative;
        font-style: italic;
        @include grid-column(6);
        &:first-child {
            &:after {
                content: '/';
                position: absolute;
                right: 30%;
            }
        }
    }
}
.country-wrapper {
	@include grid-column($columns: 10, $center: true);
}
.block-Aqua {
    background: $mid-blue;
    background: rgba($mid-blue, .6);
}
#animatedLeaderboard {
    background-image: url('../images/tile_double.png');
    background-repeat: repeat-x;
    background-position: center bottom;
    padding-top: 26px;
    padding-bottom: 26px;
    margin-bottom: 20px;
    .page-media {
        text-align: center;
        img {
            display: none;
            z-index: 3;
            &.last {
                display: inline-block;
                margin: 0 auto 10px;
            }
        }
    }    
    .prod-waves {
        display: block;
        position: absolute;
        bottom: 14px;
        width: 200%;
        height: 228px;
        z-index: 1;
        &.wave-one {
            z-index: 5;
            background: url('../images/prod_wave_1.png') repeat-x 0 100%;
            background-size: auto 100%;
        }
        &.wave-two {
            height: 237px;
            background: url('../images/prod_wave_2.png') repeat-x 0 100%;
            background-size: auto 100%;
        }
    }
    .middle {
    	text-align: center;
    	z-index: 10;
    	@include grid-column($columns: 10, $center: true);
    	p {
    		font-size: 22px;
    		font-weight: 600;
    		line-height: 1.4;
    	}
    }
    h2 {
        font-size: rem-calc(30);
    	@include text-stroke();
    }
    .bubble {
        position: absolute;
        display: block;
        z-index: 10;
        &.bubble-1 {
            @include imgRep('../images/bubble_1.png',10px,10px,no-repeat);
            position: absolute;
            &.left {
                left: 30px;
                bottom: 50px;
            }
            &.right {
                right: 28px;
                bottom: 75px;
            }
        }
        &.bubble-2 {
            @include imgRep('../images/bubble_2.png',13px,13px,no-repeat);
            position: absolute;
            &.left {
                left: 48px;
                bottom: 80px;
            }
            &.right {
                right: 68px;
                bottom: 77px;
            }
        }
        &.bubble-3 {
            @include imgRep('../images/bubble_3.png',16px,16px,no-repeat);
            position: absolute;
            &.left {
                left: 43px;
                bottom: 135px;
            }
            &.right {
                right: 27px;
                bottom: 142px;
            }
        }
        &.bubble-4 {
            @include imgRep('../images/bubble_4.png',19px,19px,no-repeat);
            position: absolute;
            &.left {
                left: 17px;
                bottom: 100px;
            }
            &.right {
                right: 15px;
                bottom: 87px;
            }
        }
    }
}



@media #{$medium-up} {
	.country-wrapper {
		@include grid-column(6);
	}
	#animatedLeaderboard {
		padding-top: 36px;
        .middle {
            // @include grid-column($columns: 9, $center: true);
            z-index: 10;
            position: absolute;
            bottom: 26px;
            left: 8.4%;
        }
        .prod-waves {
            bottom: 26px;
            height: 148px;
            &.wave-one {
                @include animation(wave, 8s, linear, 0s, infinite, normal);
            }
            &.wave-two {
                height: 157px;
                @include animation(wave, 3s, linear, 0s, infinite, normal);
            }
        }
        .boat {
            bottom: 147px;
        }
        .page-media {
			padding-bottom: 86px;
	        img {
	            position: relative;
	            display: inline-block;
	            z-index: 3;
	            @include animation(bob, 8s, ease-in-out, 250ms, infinite, normal);
	            &.img-2 {
	                @include animation(bob, 7s, ease-in-out, 150ms, infinite, reverse);
	            }
	            &.img-3 {
	                @include animation(bob, 9s, ease-in-out, 50ms, infinite, alternate);
	            }
	            &.last {
	                display: none;
	            }
        	}
        }
	    .bubble {
	        opacity: 0;
	        -webkit-transform-origin: 50% 50%;
	        -moz-transform-origin: 50% 50%;
	        -o-transform-origin: 50% 50%;
	        transform-origin: 50% 50%;
	        &.bubble-1 {
	            @include imgRep('../images/bubble_1.png',10px,10px,no-repeat);
	            position: absolute;
	            &.left {
                    bottom: 14px;
	                left: 100px;
	                @include animation(vapor, 2.8s, linear, 50ms, infinite, normal);
	            }
	            &.right {
                    bottom: 14px;
	                right: 88px;
	                @include animation(vapor, 3.1s, linear, 150ms, infinite, normal);
	            }
	        }
	        &.bubble-2 {
	            @include imgRep('../images/bubble_2.png',13px,13px,no-repeat);
	            position: absolute;
	            &.left {
                    bottom: 14px;
	                left: 248px;
	                @include animation(vapor, 4s, linear, 250ms, infinite, normal);
	            }
	            &.right {
                    bottom: 14px;
	                right: 188px;
	                @include animation(vapor, 3.7s, linear, 220ms, infinite, normal);
	            }
	        }
	        &.bubble-3 {
	            @include imgRep('../images/bubble_3.png',16px,16px,no-repeat);
	            position: absolute;
	            &.left {
                    bottom: 14px;
	                left: 173px;
	                @include animation(vapor, 3.4s, linear, 340ms, infinite, normal);
	            }
	            &.right {
                    bottom: 14px;
	                right: 127px;
	                @include animation(vapor, 4.3s, linear, 120ms, infinite, normal);
	            }
	        }
	        &.bubble-4 {
	            @include imgRep('../images/bubble_4.png',19px,19px,no-repeat);
	            position: absolute;
	            &.left {
                    bottom: 14px;
	                left: 127px;
	                @include animation(vapor, 5.2s, linear, 120ms, infinite, normal);
	            }
	            &.right {
                    bottom: 14px;
	                right: 215px;
	                @include animation(vapor, 5.8s, linear, 80ms, infinite, normal);
	            }
	        }
	    }
    }
    .footer {
	    > div {
	        @include grid-column($columns: 10, $center: true);	
	        p {
	        	font-size: 14px;
	        }
	        a {
	        	font-weight: 600;
	        	color: $lsw-navy;
	        } 
	    }
	}
} 

@media #{$large-up} {
	.header {
		height: 100px;
	}
	.country {
		padding-top: 15px;
		min-height: 255px;
		@include grid-column($columns:12);
	}
	.country-wrapper {
		@include grid-column(3);
	}
    .boat {
        position: absolute;
        bottom: 135px;
        right: 40px;
        display: block;
        width: 154px;
        height: 80px;
        background: url('../images/boat.png') no-repeat 0 0;
        z-index: 4;
        -webkit-transition: all 12s ease;
        -moz-transition: all 12s ease;
        -o-transition: all 12s ease;
        transition: all 12s ease;
        -webkit-transform-origin: 50% 50%;
        -moz-transform-origin: 50% 50%;
        -o-transform-origin: 50% 50%;
        transform-origin: 50% 50%;
        @include animation(bob, 4s, ease-in-out, 0s, infinite, alternate);
    }
    #animatedLeaderboard {
        .middle {
            @include grid-column($columns: 8, $center: true);
            left: 16.04%;
        }
    }
} 

@include keyframes(wave) {
    0% {
        background-position: 0 100%;
    }
    100% {
        background-position: -1430px 100%;
    }
}
@include keyframes(bob) {
    0% {
        -webkit-transform: translateX(0px) translateY(0px) rotate(0deg);
        -moz-transform: translateX(0px) translateY(0px) rotate(0deg);
        -o-transform: translateX(0px) translateY(0px) rotate(0deg);
        transform: translateX(0px) translateY(0px) rotate(0deg);
    }
    25% {
        -webkit-transform: translateX(2px) translateY(-4px) rotate(2deg);
        -moz-transform: translateX(2px) translateY(-4px) rotate(2deg);
        -o-transform: translateX(2px) translateY(-4px) rotate(2deg);
        transform: translateX(2px) translateY(-4px) rotate(2deg);
    }
    50% {
        -webkit-transform: translateX(-1px) translateY(5px) rotate(-4deg);
        -moz-transform: translateX(-1px) translateY(5px) rotate(-4deg);
        -o-transform: translateX(-1px) translateY(5px) rotate(-4deg);
        transform: translateX(-1px) translateY(5px) rotate(-4deg);
    }
    80% {
        -webkit-transform: translateX(1px) translateY(-3px) rotate(3deg);
        -moz-transform: translateX(1px) translateY(-3px) rotate(3deg);
        -o-transform: translateX(1px) translateY(-3px) rotate(3deg);
        transform: translateX(1px) translateY(-3px) rotate(3deg);
    }
    100% {
        -webkit-transform: translateX(0px) translateY(0px) rotate(0deg);
        -moz-transform: translateX(0px) translateY(0px) rotate(0deg);
        -o-transform: translateX(0px) translateY(0px) rotate(0deg);
        transform: translateX(0px) translateY(0px) rotate(0deg);
    }
}
@include keyframes(vapor) {
    0% {
        opacity: 0;
        -webkit-transform: translate(0px, 0px) scale(1);
        -moz-transform: translate(0px, 0px) scale(1);
        -o-transform: translate(0px, 0px) scale(1);
        transform: translate(0px, 0px) scale(1);
    }
    15% {
        opacity: 0.75;
        -webkit-transform: translate(12px, -25px) scale(1);
        -moz-transform: translate(12px, -25px) scale(1);
        -o-transform: translate(12px, -25px) scale(1);
        transform: translate(12px, -25px) scale(1);
    }
    30% {
        opacity: 1;
        -webkit-transform: translate(-14px, -50px) scale(1);
        -moz-transform: translate(-14px, -50px) scale(1);
        -o-transform: translate(-14px, -50px) scale(1);
        transform: translate(-14px, -50px) scale(1);
    }
    50% {
        opacity: 1;
        -webkit-transform: translate(8px, -70px) scale(1);
        -moz-transform: translate(8px, -70px) scale(1);
        -o-transform: translate(8px, -70px) scale(1);
        transform: translate(8px, -70px) scale(1);
    }
    65% {
        opacity: 1;
        -webkit-transform: translate(-7px, -85px) scale(1);
        -moz-transform: translate(-7px, -85px) scale(1);
        -o-transform: translate(-7px, -85px) scale(1);
        transform: translate(-7px, -85px) scale(1);
    }
    85% {
        opacity: 0.75;
        -webkit-transform: translate(5px, -100px) scale(1.1);
        -moz-transform: translate(5px, -100px) scale(1.1);
        -o-transform: translate(5px, -100px) scale(1.1);
        transform: translate(5px, -100px) scale(1.1);
    }
    100% {
        opacity: 0;
        -webkit-transform: translate(-4px, -110px) scale(1.4);
        -moz-transform: translate(-4px, -110px) scale(1.4);
        -o-transform: translate(-4px, -110px) scale(1.4);
        transform: translate(-4px, -110px) scale(1.4);
    }
}